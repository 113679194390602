import { useElementSize } from "@mantine/hooks";
import { createRef, useEffect, useRef, useState } from "react";

const MIN_SIZE = 3;
const MAX_SIZE = 21;
const VALUE_EMPTY = -1;
const VALUE_PLAYER = 0;
const VALUE_IA = 1;
const ICON_PLAYER = "X";
const ICON_IA = "O";

function App() {
  const { ref, width, height } = useElementSize();

  const [scores, setScores] = useState([0, 0]);
  const [inLobby, setInLobby] = useState(true);
  const [gridSize, setGridSize] = useState(3);
  const [grid, setGrid] = useState([
    [VALUE_EMPTY, VALUE_EMPTY, VALUE_EMPTY],
    [VALUE_EMPTY, VALUE_EMPTY, VALUE_EMPTY],
    [VALUE_EMPTY, VALUE_EMPTY, VALUE_EMPTY],
  ]);

  const [currentPlayer, setCurrentPlayer] = useState(VALUE_PLAYER);
  const [enableIA, setEnableIA] = useState(true);

  useEffect(() => {
    ia();
  }, [currentPlayer]);

  const caseSize = ((height <= width ? height : width) / gridSize) * 0.9;

  const updateGrid = (newSize) => {
    newSize =
      newSize < MIN_SIZE ? MIN_SIZE : newSize > MAX_SIZE ? MAX_SIZE : newSize;

    const newGrid = [];
    for (let i = 0; i < newSize; i++) {
      newGrid.push(Array(newSize).fill(VALUE_EMPTY));
    }

    setGridSize(newSize);
    setGrid(newGrid);
  };

  const isGridFull = () => {
    let count = 0;
    for (let r = 0; r < grid.length; r++) {
      for (let c = 0; c < grid[r].length; c++) {
        count += grid[r][c] !== VALUE_EMPTY ? 1 : 0;
      }
    }

    return count === gridSize * gridSize;
  };

  const detectWin = () => {
    for (let r = 0; r < grid.length; r++) {
      for (let c = 0; c < grid[r].length; c++) {
        const currentValue = grid[r][c];
        if (currentValue === VALUE_EMPTY) {
          continue;
        }

        // horizontal
        if (c > 0 && c < gridSize - 1) {
          if (
            currentValue === grid[r][c - 1] &&
            currentValue === grid[r][c + 1]
          ) {
            return true;
          }
        }
        // vertical
        if (r > 0 && r < gridSize - 1) {
          if (
            currentValue === grid[r - 1][c] &&
            currentValue === grid[r + 1][c]
          ) {
            return true;
          }
        }
        // diagonals
        if (r > 0 && r < gridSize - 1 && c > 0 && c < gridSize - 1) {
          // top left to bottom right
          if (
            currentValue === grid[r - 1][c - 1] &&
            currentValue === grid[r + 1][c + 1]
          ) {
            return true;
          }
          // top right to bottom left
          if (
            currentValue === grid[r - 1][c + 1] &&
            currentValue === grid[r + 1][c - 1]
          ) {
            return true;
          }
        }
      }
    }
    return false;
  };

  const placeCase = (row, col, value) => {
    if (grid[row][col] !== VALUE_EMPTY || inLobby) {
      // Can't play on an already played case
      return;
    }

    const newGrid = [...grid];
    newGrid[row][col] = value;

    setGrid(newGrid);
    const hasWin = detectWin();
    if (hasWin || isGridFull()) {
      if (hasWin) {
        // set scores
        const newScores = [...scores];
        newScores[currentPlayer] += 1;
        setScores(newScores);
      }
      setInLobby(true);
      return;
    }

    setCurrentPlayer(value === VALUE_PLAYER ? VALUE_IA : VALUE_PLAYER);
  };

  const ia = () => {
    if (!enableIA) {
      return;
    }
    if (currentPlayer !== VALUE_IA) {
      return;
    }

    const emptyCases = [];
    for (let r = 0; r < grid.length; r++) {
      for (let c = 0; c < grid[r].length; c++) {
        if (grid[r][c] === VALUE_EMPTY) {
          emptyCases.push({ r: r, c: c });
        }
      }
    }

    const targetMove =
      emptyCases[Math.floor(Math.random() * emptyCases.length)];
    placeCase(
      targetMove.r,
      targetMove.c,
      currentPlayer === VALUE_PLAYER ? VALUE_PLAYER : VALUE_IA
    );
  };

  return (
    <div className="min-h-screen flex justify-center items-center flex-col">
      <div className="p-4 flex flex-col space-y-4">
        {inLobby && (
          <div className="flex flex-row items-center space-x-12">
            <div className="flex flex-1 flex-row items-center space-x-2 space-between">
              <button
                type="button"
                onClick={() => updateGrid(gridSize - 1)}
                className="bg-teal-500 hover:bg-teal-700 px-4 py-1.5 text-sm leading-5 rounded-md font-semibold text-white"
              >
                {"-"}
              </button>
              <span className="font-semibold">{`${gridSize}x${gridSize}`}</span>
              <button
                type="button"
                onClick={() => updateGrid(gridSize + 1)}
                className="bg-teal-500 hover:bg-teal-700 px-4 py-1.5 text-sm leading-5 rounded-md font-semibold text-white"
              >
                {"+"}
              </button>
            </div>
            <button
              className={`${
                enableIA
                  ? "bg-teal-500 hover:bg-teal-700"
                  : "bg-pink-500 hover:bg-pink-700"
              } px-4 py-1.5 text-sm leading-5 rounded-md font-semibold text-white`}
              onClick={() => {
                setEnableIA(!enableIA);
              }}
            >
              IA : {enableIA ? "Oui" : "Non"}
            </button>
          </div>
        )}

        <div className="flex flex-row justify-center items-center space-x-8">
          <span className="text-2xl">
            <span className="font-bold">{ICON_PLAYER}</span> :{" "}
            {scores[VALUE_PLAYER]}
          </span>
          <span className="text-2xl">{"VS"}</span>
          <span className="text-2xl">
            <span className="font-bold">{ICON_IA}</span> : {scores[VALUE_IA]}
          </span>
        </div>
      </div>
      <div ref={ref} className="flex flex-1 justify-center items-center w-full">
        <div
          className={`grid gap-0`}
          style={{
            gridTemplateColumns: `repeat(${gridSize}, minmax(0, 1fr))`,
          }}
        >
          {grid.map((row, rIdx) =>
            row.map((gameCase, cIdx) => (
              <div
                key={`${rIdx}-${cIdx}`}
                className={`box-border p-0 border-2 flex justify-center items-center`}
                style={{
                  width: caseSize,
                  height: caseSize,
                }}
                onClick={() => {
                  placeCase(
                    rIdx,
                    cIdx,
                    currentPlayer === VALUE_PLAYER ? VALUE_PLAYER : VALUE_IA
                  );
                }}
              >
                <span style={{ fontSize: caseSize / 2 }}>
                  {gameCase === VALUE_IA
                    ? ICON_IA
                    : gameCase === VALUE_PLAYER
                    ? ICON_PLAYER
                    : ""}
                </span>
              </div>
            ))
          )}
        </div>
      </div>
      {inLobby && (
        <div className="p-4 flex flex-col">
          <button
            className="bg-teal-500 hover:bg-teal-700 px-6 py-2.5 text-xl leading-5 rounded-md font-semibold text-white"
            onClick={() => {
              setInLobby(false);
              updateGrid(gridSize);
            }}
          >
            Démarrer
          </button>
        </div>
      )}
      {!inLobby && (
        <div className="flex flex-row items-center p-4 space-x-12">
          <span className="text-3xl">
            Tour de{" "}
            <span className="font-bold">
              {currentPlayer === VALUE_PLAYER ? ICON_PLAYER : ICON_IA}
            </span>{" "}
            !
          </span>
        </div>
      )}
    </div>
  );
}

export default App;
